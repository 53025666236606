import "./App.css";
import Login from "./Login";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "./services/context";
import { getUser } from "./services/storage";

function App() {
  const authCtx = useContext(AppContext);
  const { user, onUserChange } = authCtx;

  return (
    <div className="row">
      <Routes>
        <Route path="/" element={<Login />} />
        {
          user.isAuth ? (
            <Route path="/dashboard/*" element={<Dashboard />} />
          ) : (
            <Route path="/*" element={<Login />} />
          )
        }
      </Routes>
    </div>
  );
}

export default App;
