import React, { useContext, useEffect, useRef, useState } from "react";
import { apiOrganisation } from "../../services/api";
import { useParams } from "react-router-dom";
import requestOrganisation from "../../services/requestOrganisation";
import upld from "../../assets/imgs/upld.png";
import edit from "../../assets/imgs/edit.png";
import medicsoft from "../../assets/imgs/medicsoft.png";
import { AppContext } from "../../services/context";

const DetailOrganisation = () => {
  const authCtx = useContext(AppContext);
  const { user, onUserChange } = authCtx;
  const [jsData, setJsData] = useState({
    registrationReference: "",
    organisationName: "",
    firstNameAdmin: "",
    lastNameAdmin: "",
    emailAdmin: "",
    cnib: "",
    cityAdmin: "",
    phoneOrganisation: "",
    countryAdmin: "",
    countryOrganisation: "",
    cityOrganisation: "",
    sectorOrganisation: "",
    birhtDate: "",
    createdDate: "",
    address: "",
    fullNameLeader: "",
    emailLeader: "",
    phoneLeader: "",
    phoneAdmin: "",
    nationalReference: "",
    countries: {},
    cities: {},
    departmentList: null,
  });
  const btnUpload = useRef();
  const [imgProfile, setImgProfile] = useState();
  const [imgPicture, setImgPicture] = useState();
  const [refresh, setRefresh] = useState();
  const [message, setMessage] = useState("");
  const [modalNotifyMsg, setModalNotifyMsg] = useState("");
  const { id } = useParams();
  const notifyRef = useRef();

  useEffect(() => {
    requestOrganisation
      .get(`${apiOrganisation.get}/${id}`)
      .then((res) => {
        setJsData(res.data);

        console.log(res.data);
      })
      .catch((error) => {});
  }, [refresh]);

  const editProfilePicture = (e) => {
    e.preventDefault();
    setMessage("Veuillez patienté... ")
    console.log(imgPicture);

    let data = new FormData(); //formdata object
    data.append("logo", imgPicture); //append the values with key, value pair
    console.log({ logo: imgPicture });

    requestOrganisation
      .post(apiOrganisation.image + "/" + jsData.registrationReference, data)
      .then((res) => {
        console.log("enregistrement ok");
        setMessage("")
        setRefresh(refresh + 1);
        /*updateProfile();*/
        setModalNotifyMsg("La photo a été modifiée");
        notifyRef.current.click();
      })
      .catch((error) => {
        console.log(error);
        setMessage("La modification a échoué")
        setModalNotifyMsg("La modification a échoué");
        notifyRef.current.click();
      });
  };
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 className="h2">Aperçu d’une organisation</h1>
      </div>
      <div className="row my-5">
        <div className="col-10 col-sm-8 mx-auto col-md-5 col-lg-4">
          <div className="border border-1 border-radius p-4 mb-4">
            <span className="text-meduim text-bold ps-1 d-block">
              Fiche de l’organisation
            </span>
            <span className="d-block text-32 text-bold my-2">
              {jsData.organisationName}
            </span>
            <div className="my-1">
              <span className="ps-1 d-inline-block">ID Organisation: </span>
              <span className="ps-1 d-inline-block text-bold ms-2">
                P12902{" "}
              </span>
            </div>
            <div className="my-1">
              <span className="ps-1 d-inline-block">Départements: </span>
              {[...Array(2).keys()].map((data, idx) => {
                return (
                  <span
                    key={idx}
                    className="text-ultra-small ps-1 d-inline-block ms-2"
                  >
                    Département {data + 1}{" "}
                  </span>
                );
              })}
            </div>
            <div className="my-1">
              <span className="ps-1 d-inline-block">
                {jsData.cityOrganisation},{" "}
              </span>
              <span className="ps-1 d-inline-block text-bold ms-2">
                {jsData.countryOrganisation}{" "}
              </span>
            </div>
          </div>
          <div className="border border-1 border-radius p-4 my-4">
            <span className="text-meduim text-bold ps-1 d-block">
              Adminisatration de l’organisation
            </span>
            <span className="d-block text-32 text-bold mt-2">
              {jsData.lastNameAdmin + " " + jsData.firstNameAdmin}
            </span>
            <div className="mb-3">
              <span className="ps-1 d-inline-block">{jsData.cityAdmin}, </span>
              <span className="ps-1 d-inline-block text-bold ms-2">
                {jsData.countryAdmin}{" "}
              </span>
            </div>
            <div className="my-1 d-flex justify-content-between">
              <span className="ps-1 d-inline-block">Email: </span>
              <span className="ps-1 d-inline-block text-bold ms-2">
                {jsData.emailAdmin}{" "}
              </span>
            </div>
            <div className="my-1 d-flex justify-content-between">
              <span className="ps-1 d-inline-block">Téléphone: </span>
              <span className="ps-1 d-inline-block text-bold ms-2">
                {jsData.phoneAdmin}
              </span>
            </div>
            <div className="my-1 d-flex justify-content-between">
              <span className="ps-1 d-inline-block">Date de naissance: </span>
              <span className="ps-1 d-inline-block text-bold ms-2">
                {jsData.birhtDate}
              </span>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-7 col-lg-6 mx-auto border border-1 border-radius p-4">
          <span className="text-meduim text-bold">
            Informations sur l’organisation
          </span>
          <hr className="m-0 mb-3" />
          <div className="my-1 d-flex justify-content-between">
            <span className="ps-1 d-inline-block">Nom: </span>
            <span className="ps-1 d-inline-block text-bold ms-2">
              {jsData.organisationName}
            </span>
          </div>
          <div className="my-1 d-flex justify-content-between">
            <span className="ps-1 d-inline-block">Référence nationale: </span>
            <span className="ps-1 d-inline-block text-bold ms-2">
              {jsData.nationalReference}
            </span>
          </div>
          <div className="my-1 d-flex justify-content-between">
            <span className="ps-1 d-inline-block">Date de céation: </span>
            <span className="ps-1 d-inline-block text-bold ms-2">
              {jsData.createdDate}
            </span>
          </div>
          <div className="my-1 d-flex justify-content-between">
            <span className="ps-1 d-inline-block">Départements: </span>
            <span className="ps-1 d-inline-block">
              {[...Array(2).keys()].map((data, idx) => {
                return (
                  <span
                    key={idx}
                    className="text-ultra-small ps-1 d-inline-block ms-2"
                  >
                    Département {data + 1}{" "}
                  </span>
                );
              })}
            </span>
          </div>
          <span className="text-meduim text-bold d-inline-block mt-4">
            Informations sur le responsable de l’organisation
          </span>
          <hr className="m-0 mb-3" />
          <div className="my-1 d-flex justify-content-between">
            <span className="ps-1 d-inline-block">Nom et Prenoms: </span>
            <span className="ps-1 d-inline-block text-bold ms-2">
              {jsData.fullNameLeader}
            </span>
          </div>
          <div className="my-1 d-flex justify-content-between">
            <span className="ps-1 d-inline-block">Email: </span>
            <span className="ps-1 d-inline-block text-bold ms-2">
              {jsData.emailLeader}
            </span>
          </div>
          <div className="my-1 d-flex justify-content-between">
            <span className="ps-1 d-inline-block">Téléphone: </span>
            <span className="ps-1 d-inline-block text-bold ms-2">
              {jsData.phoneLeader}
            </span>
          </div>
          <span className="text-meduim text-bold d-inline-block mt-4">
            Adresse et contacts de l’organisation
          </span>
          <hr className="m-0 mb-3" />
          <div className="my-1 d-flex justify-content-between">
            <span className="ps-1 d-inline-block">Pays: </span>
            <span className="ps-1 d-inline-block text-bold ms-2">
              {jsData.countryOrganisation}
            </span>
          </div>
          <div className="my-1 d-flex justify-content-between">
            <span className="ps-1 d-inline-block">Ville: </span>
            <span className="ps-1 d-inline-block text-bold ms-2">
              {jsData.cityOrganisation}
            </span>
          </div>
          <div className="my-1 d-flex justify-content-between">
            <span className="ps-1 d-inline-block">Adresse: </span>
            <span className="ps-1 d-inline-block text-bold ms-2">
              {jsData.sectorOrganisation}
            </span>
          </div>
          <div className="my-1 d-flex justify-content-between">
            <span className="ps-1 d-inline-block">Téléphone: </span>
            <span className="ps-1 d-inline-block text-bold ms-2">
              {jsData.phoneOrganisation}
            </span>
          </div>
          <span className="text-meduim text-bold d-inline-block mt-4">
            Fichiers
          </span>
          <hr className="m-0 mb-3" />
          <div className="">
            <div className="w-100 position-relative">
              <img
                width="100%"
                src={jsData.documentHeadName ? "https://laafivisionmedical.com/"+jsData.documentHeadName : medicsoft}
                alt=""
              />
              <img
                className="position-absolute"
                src={upld}
                alt=""
                style={{ bottom: "20px", right: "20px" }}
                onClick={(e) => {
                  e.preventDefault();
                  btnUpload.current.click();
                }}
              />
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  //console.log(e.target.files[0])
                  setImgProfile(URL.createObjectURL(e.target.files[0]));
                  setImgPicture(e.target.files[0]);
                }}
                ref={btnUpload}
                hidden
              />
            </div>
            <div className="my-3">
              <button
                className="btn btn-primary me-2"
                data-bs-dismiss="modal"
                style={{ width: "85%" }}
                onClick={editProfilePicture}
              >
                Enregistrer l'image
              </button>
              <img
                src={edit}
                alt=""
                onClick={(e) => {
                  e.preventDefault();
                  btnUpload.current.click();
                }}
              />
            </div>
            <p className="text-danger fw-bold">{message && message}</p>
          </div>
        </div>
      </div>
      <div className="modal fade" id="notifyRef">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h4 className="modal-title text-meduim text-bold"></h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">{modalNotifyMsg}</div>

            <div className="modal-footer border-0 d-flex justify-content-start">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  setModalNotifyMsg("");
                  //navigate("/dashboard/organisations");
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
      <input
        type="hidden"
        ref={notifyRef}
        data-bs-toggle="modal"
        data-bs-target="#notifyRef"
        onClick={(e) => {
          e.preventDefault();
          
        }}
      />
    </>
  );
};

export default DetailOrganisation;
