import React, { useContext, useEffect } from "react";
import { Link, Navigate, NavLink, Route, Routes } from "react-router-dom";
import mc from "./assets/imgs/Medicsoft_logo.png";
import msg from "./assets/imgs/msg.png";
import supp from "./assets/imgs/supp.png";
import userp from "./assets/imgs/user.png";
import home from "./assets/imgs/home.png";
import employe from "./assets/imgs/employe.png";
import rendv from "./assets/imgs/rendezvous.png";
import agenda from "./assets/imgs/agenda.png";
import patient from "./assets/imgs/patient.png";
import Employe from "./pages/Customer";
import Settings from "./pages/Settings";
import Home from "./pages/Home";
import Meet from "./pages/Meet";
import Notebook from "./pages/Notebook";
import Patient from "./pages/Patient";
import NotebookGard from "./pages/NotebookGard";
import { useNavigate } from "react-router-dom/dist";
import Customer from "./pages/Customer";
import Pharmacy from "./pages/Pharmacy";
import WidocUser from "./pages/WidocUser";
import Organisation from "./pages/Organisation";
import { AppContext, initialUser } from "./services/context";
import { deleteUser } from "./services/storage";

const Dashboard = () => {
  const authCtx = useContext(AppContext);
  const { user, onUserChange } = authCtx;
  let navigate = useNavigate();

  useEffect(() => {
    //return navigate("/dashboard/")
    isAuth();
  }, [user]);
  const isAuth = () => {
    if (user.isAuth == false || user.token == null || user.token == "") {
      console.log(`connexion échoué, isAuth`);
      console.log(user);

      //return navigate("/");
    } else {
      console.log("isAuth true");
    }
  };
  const deconnect = () => {
    deleteUser();
    onUserChange(initialUser);
  };
  return (
    <>
      {user.isAuth ? (
        <>
          <header className="container-fluid navbar navbar-dark bg-white sticky-top flex-md-nowrap px-0 py-4 shadow d-md-none">
            <Link
              className="navbar-brand1 bg-white col-md-3 col-lg-2 me-0 px-3"
              to="#"
            >
              <img src={mc} alt="" />
            </Link>
            <button
              className="navbar-toggler p-2 position-absolute mx-0 my-4 d-md-none collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#sidebarMenu"
              aria-controls="sidebarMenu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </header>

          <div className="container-fluid">
            <div className="row">
              <nav
                id="sidebarMenu"
                className="col-md-1 col-lg-2 d-md-block bg-white sidebar collapse p-0"
              >
                <div className="position-sticky h-100 text-small">
                  <div className="col-12 d-none d-md-block text-center py-5 m-0">
                    <img src={mc} alt="" />
                  </div>
                  <div className="d-md-none py-3"></div>
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <NavLink
                        to="/dashboard/"
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                      >
                        <span className="d-none d-md-block d-lg-none wd-0">
                          <img src={home} alt="" />
                        </span>
                        <span
                          className="d-block d-md-none d-lg-block wd-80"
                          data-bs-toggle="collapse"
                          data-bs-target="#sidebarMenu.show"
                        >
                          Accueil
                        </span>
                      </NavLink>
                    </li>
                    {/***
                 <li className="nav-item">
                  <NavLink to="/dashboard/clients" className={({ isActive }) => isActive ? "nav-link active":"nav-link"}>
                    <span className="d-none d-md-block d-lg-none wd-0">
                      <img src={employe} alt="" />
                    </span>
                    <span
                      className="d-block d-md-none d-lg-block wd-80"
                      data-bs-toggle="collapse"
                      data-bs-target="#sidebarMenu.show"
                    >
                      Clients
                    </span>
                  </NavLink>
                </li>
                   */}
                    <li className="nav-item">
                      <NavLink
                        to="/dashboard/organisations"
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                      >
                        <span className="d-none d-md-block d-lg-none wd-0">
                          <img src={rendv} alt="" />
                        </span>
                        <span
                          className="d-block d-md-none d-lg-block wd-80"
                          data-bs-toggle="collapse"
                          data-bs-target="#sidebarMenu.show"
                        >
                          Organisations
                        </span>
                      </NavLink>
                    </li>



                    {/*<li className="nav-item">
                      <NavLink
                        to="/dashboard/pharmacies"
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                      >
                        <span className="d-none d-md-block d-lg-none wd-0">
                          <img src={agenda} alt="" />
                        </span>
                        <span
                          className="d-block d-md-none d-lg-block wd-80"
                          data-bs-toggle="collapse"
                          data-bs-target="#sidebarMenu.show"
                        >
                          Pharmacies
                        </span>
                      </NavLink>
                    </li>*/}
                    <li className="nav-item">
                      <NavLink
                        to="/dashboard/utilisateurs"
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                      >
                        <span className="d-none d-md-block d-lg-none wd-0">
                          <img src={patient} alt="" />
                        </span>
                        <span
                          className="d-block d-md-none d-lg-block wd-80"
                          data-bs-toggle="collapse"
                          data-bs-target="#sidebarMenu.show"
                        >
                          Utilisateurs Widoc
                        </span>
                      </NavLink>
                    </li>
                    {/*<li className="nav-item">
                  <NavLink to="/dashboard/agenda_garde" className={({ isActive }) => isActive ? "nav-link active":"nav-link"}>
                    <span className="d-none d-md-block d-lg-none wd-0">
                      <img src={agenda} alt="" />
                    </span>
                    <span
                      className="d-block d-md-none d-lg-block wd-80"
                      data-bs-toggle="collapse"
                      data-bs-target="#sidebarMenu.show"
                    >
                      Agenda de Garde
                    </span>
                  </NavLink>
                </li>*/}
                  </ul>

                  <ul className="nav flex-column w-100 position-absolute bottom-0 mb-5">
                    {/*<li className="nav-item link-border1">
                  <Link to="#" className="nav-link">
                    <span className="d-none d-md-block d-lg-none wd-0">
                      <img src={msg} alt="" />
                    </span>
                    <span className="d-block d-md-none border border-2 d-lg-block wd-80 p-2">
                      <img src={msg} alt="" />
                      <span className="d-inline-block ms-3">Messagerie</span>
                    </span>
                  </Link>
                </li>*/}
                    <li className="nav-item link-border1">
                      <Link to="#" className="nav-link">
                        <span className="d-none d-md-block d-lg-none wd-0">
                          <img src={supp} alt="" />
                        </span>
                        <span className="d-block d-md-none border border-2 d-lg-block wd-80 p-2">
                          <img src={supp} alt="" />{" "}
                          <span className="d-inline-block ms-3">
                            Administration
                          </span>
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item link-border link-border-primary dropdown">
                      <Link
                        to="#"
                        className="nav-link d-flex align-items-center text-decoration-none"
                        id="dropdownUser2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="d-none d-md-block d-lg-none ">
                          <img src={userp} alt="" />
                        </span>
                        <span className="d-block d-md-none border border-2 d-lg-block wd-80 p-2">
                          <img
                            className="d-inline-block"
                            src={userp}
                            alt=""
                            style={{ marginLeft: "-6px", marginTop: "-6px" }}
                          />
                          <span className="d-inline-block ms-2">
                          {user.name}
                          </span>
                        </span>
                      </Link>
                      <ul
                        className="ms-5 my-2 dropdown-menu text-small shadow"
                        aria-labelledby="dropdownUser2"
                      >
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/dashboard/parametre"
                          >
                            Paramètres
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={(e) => {
                              e.preventDefault();
                              deconnect();
                            }}
                          >
                            Se déconnecter
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>

              <main className="col-md-11 ms-sm-auto col-lg-10 px-md-4 pt-5 h-90 text-small">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/clients" element={<Customer />} />
                  <Route path="/pharmacies" element={<Pharmacy />} />
                  <Route path="/utilisateurs" element={<WidocUser />} />
                  <Route path="/organisations/*" element={<Organisation />} />



                  <Route path="/parametre" element={<Settings />} />
                </Routes>
              </main>
              <div className="col-12 col-md-9 ms-sm-auto col-lg-10 px-md-4 text-center text-md-start">
          <div className="text-small d-inline-block  my-1 me-2">
              © Laafi Vision Médical, Tous droits réservés.
            </div>
            <div className="d-inline-block ms-md-5">
            <Link to="#" className="text-small link d-inline-block my-1 me-2">
              Conditions générales
            </Link>
            <Link to="#" className="text-small link d-inline-block my-1">
              Politiques de confidentialités
            </Link>
            </div>
          </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Navigate to="/" />
        </>
      )}
    </>
  );
};

export default Dashboard;
