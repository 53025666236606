import React, { useContext, useEffect } from "react";
import hp from "../assets/imgs/home_profile.png";
import homeBg from "../assets/imgs/home_bg_1.png";
import { AppContext } from "../services/context";
import { Link, useNavigate } from "react-router-dom";
import { getUser } from "../services/storage";
import grd from "../assets/imgs/gard.png";

const Home = () => {
  const authCtx = useContext(AppContext);
  const { user, onUserChange } = authCtx;
  let navigate = useNavigate();
  useEffect(()=>{
    onUserChange(getUser())
    isAuth()
  },[user.isAuth])
  const isAuth = () => {
    if (user.isAuth === false || user.token === null || user.token === "") {
      console.log(`connexion échoué, isAuth`);
      console.log(user)
  
      return navigate("/");
    }
  }
  return (
    <>
      <div className="row">
        <h1 className="h2">Accueil</h1>
      </div>
      <div className="row my-4">
        
        <div className="col-12 col-md-12 col-lg-7 bg-primary px-0 border-radius overflow-hidden d-flex align-items-center mt-4 line-height">
          <img height="150px" src={homeBg} alt="" />
          <div className="d-inline-block mx-3 line-height">
            <span className="text-white text-16">Bonjour</span> <br />
            <span className="text-white text-32 text-bold">
              {user.name}
            </span>{" "}
            <br />
            <div className="d-inline-block bg-white border-radius px-2 mt-2">
              <img src={grd} alt="" />
              <span>
                {" "}
                Vous êtes de garde cette semaine,{" "}
                <Link to="#">Voir plus en cliquant ici</Link>
              </span>
            </div>
          </div>
        </div>
        
        <div className="col-12 col-md-12 col-lg-4 mx-lg-3 bg-secondary border-radius d-flex align-items-center justify-content-center mt-4">
          <div className="row py-2">
            <div className="col-7">
              <div className="align-middle">
                <span className="d-block">Connecté en tant que</span>
                <span className="d-block text-meduim text-bold">
                  {user.name}
                </span>
                <span className="d-block">
                  <span
                    className=""
                    
                  >
                    Adminstrateur(rice) de la plateforme
                  </span>
                </span>
              </div>
            </div>
            <div className="col-4">
              <img
                className="rounded-circle"
                width="100px"
                src={hp}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
