import React, { useContext, useEffect, useState } from "react";
import view from "../../assets/imgs/view.png";
import edit from "../../assets/imgs/edit.png";
import del from "../../assets/imgs/delete.png";
import back from "../../assets/imgs/back.png";
import sui from "../../assets/imgs/sui.png";
import userProfile from "../../assets/imgs/userinfo.png";
import requestEmploye from "../../services/requestEmploye";
import { apiEmploye, apiOrganisation } from "../../services/api";
import FormNotify from "../../components/FormNotify";
import { useNavigate } from "react-router-dom";
import requestOrganisation from "../../services/requestOrganisation";
import { AppContext } from "../../services/context";
import Loading from "../../components/Loading";
import { matrice, onSearch } from "../../services/service";

const ListOrganisation = () => {
  const [refresh, setRefresh] = useState(0);
  const [search, setSearch] = useState("");
  const [dele, setDelete] = useState([]);
  const [datas, setDatas] = useState([]);
  const [list, setList] = useState([]);
  const [jsData, setJsData] = useState({
    registrationReference: "",
    firstName: "",
    lastName: "",
    cnib: "",
    title: "",
    birthdate: "",
    specialisation: "",
    specialisations: {},
    classification: "",
    classifications: {},
    email: "",
    phone: "",
    medicalStaff: "",
  });
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [cnib, setCnib] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [classification, setClassification] = useState("");
  const [specialisation, setSpecialisation] = useState("");
  const [userInfos, setUserInfos] = useState({
    lastName: "",
    firstName: "",
    cnib: "",
    department: "",
    fonction: "",
    birthDate: "",
  });
  const [notifyBg, setNotifyBg] = useState("");
  const [notifyTitle, setNotifyTitle] = useState("");
  const [notifyMessage, setNotifyMessage] = useState("");
  const [formValidate, setFormValidate] = useState("needs-validation");
  let navigate = useNavigate();
  const authCtx = useContext(AppContext);
  const { user, onUserChange } = authCtx;
  const [stopLoad, setStopLoad] = useState(false);
  const [fail, setFail] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const header = {
    headers: { Authorization: `Bearer ${user.token}` },
  };

  useEffect(() => {
    isAuth();
    get();
  }, [refresh]);

  const isAuth = () => {
    console.log(user);
    if (user.isAuth === false || user.token == null || user.token === "") {
      console.log(`connexion échoué, isAuth`);
      console.log(user);

      return navigate("/");
    }
  };

  const get = () => {
    requestOrganisation
      .get(apiOrganisation.getAll, header)
      .then((res) => {
        const data = matrice(res.data);
        setPagination(data.list);
        if (data.list.length !== 0) {
          setDatas(data.list[0]);
          setList(data.list[0]);
          setTotalPage(data.counter);
        } else {
          setTotalPage(data.counter + 1);
        }
        setStopLoad(true);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
        setFail(true);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    configNotify("loading", "", "Ajout d’un nouvel(le) employé(e) en cours...");
    //console.log(jsData)
    requestEmploye
      .post(apiEmploye.post, jsData)
      .then((res) => {
        console.log("enregistrement ok");
        setRefresh(refresh + 1);
        configNotify(
          "success",
          "Ajout réussi",
          "Les informations ont bien été enrégistrées"
        );
      })
      .catch((error) => {
        console.log(error);
        configNotify(
          "danger",
          "Ouppss!!",
          "Une erreur est survenue, veuillez reesayer plus tard..."
        );
      });
  };

  const onDelete = (e) => {
    e.preventDefault();
    console.log(dele);
    requestOrganisation
      .delete(apiOrganisation.delete, { data: dele })
      .then((res) => {
        console.log("suppression ok");
        setDelete([]);
        setRefresh(refresh + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteList = (data) => {
    if (data.checkValue === undefined || data.checkValue === false) {
      data.checkValue = true;
      setDelete([...dele, data.registrationReference]);
    } else {
      data.checkValue = false;
      const list = dele.filter(function (ref) {
        return ref !== data.registrationReference;
      });
      setDelete(list);
    }
  };

  const fValidate = (cl) => {
    setFormValidate(cl);
  };

  const configNotify = (bg, title, message) => {
    setNotifyBg(bg);
    setNotifyTitle(title);
    setNotifyMessage(message);
  };

  const makeSearch = (e) => {
    e.preventDefault();
    onSearch(e, setList, datas, [
      "organisationName",
      "firstNameAdmin",
      "lastNameAdmin",
      "emailAdmin",
      "cnib",
      "cityAdmin",
      "phoneOrganisation",
      "fullNameLeader",
      "emailLeader",
      "phoneLeader",
      "phoneAdmin",
      "birhtDate",
      "address",
      "nationalReference",
      "sectorOrganisation",
      "cityOrganisation",
      "countryOrganisation",
      "countryAdmin",
      "countries",
      "cities",
      "createdDate",
      "emailOrganisation",
    ]);
  };
  const makePagination = (e, page) => {
    e.preventDefault();
    if (page === "suiv" && pageNumber < Number(totalPage) - 1) {
      setPageNumber(pageNumber + 1);
      setList(pagination[pageNumber + 1]);
      setDatas(pagination[pageNumber + 1]);
    }
    if (page === "prece" && pageNumber >= 1) {
      setPageNumber(pageNumber - 1);
      setList(pagination[pageNumber - 1]);
      setDatas(pagination[pageNumber - 1]);
    }
  };
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 className="h2">Liste des organisations</h1>
        <div className="btn-toolbar">
          <button
            className="btn btn-primary"
            type="button"
            onClick={(e) => {
              //getJsData(e)
              navigate(`formulaire`);
            }}
          >
            +
          </button>
        </div>
      </div>

      <div className="row my-4">
        <div className="col-12">
          <div className="d-inline-block">
            <input
              type="text"
              className="form-control search"
              placeholder="Nom, contact ou adresse de l'organisation"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                makeSearch(e);
              }}
            />
          </div>
          <div className="btn-group">
            <div className="d-inline-block my-1 mx-1"
            onClick={(e) => makePagination(e, "prece")}
            >
              <img src={back} alt="" />
            </div>
            <div className="d-inline-block my-1 mx-1"
            onClick={(e) => makePagination(e, "suiv")}
            >
              <img src={sui} alt="" />
            </div>
          </div>
          <div className="d-inline-block my-1 mx-1 text-meduim text-bold">
          {pageNumber + 1}/{totalPage}
          </div>
        </div>
      </div>
      <p className="text-ultra-small">{list.length} éléments affichés</p>
      <Loading data={list} stopLoad={stopLoad} fail={fail}>
        <div className="table-responsive-sm">
          <table className="table table-striped align-middle">
            <thead>
              <tr className="align-middle">
                <th scope="col" className="border-raduis-left">
                  #
                </th>
                <th scope="col">Nom de l’organisation</th>
                <th scope="col">Contacts</th>
                <th scope="col">Adresse</th>
                <th scope="col" className="text-center">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {list.map((data, idx) => {
                //data.checkValue = false
                return (
                  <tr key={idx}>
                    <td>
                      <input
                        type="checkbox"
                        value="seleted"
                        onChange={() => {
                          deleteList(data);
                        }}
                      />
                    </td>
                    <td>{data.organisationName}</td>
                    <td>
                      <span className="text-bold">
                        {data.emailOrganisation}
                      </span>
                      <br />
                      <span>{data.phoneOrganisation}</span>
                    </td>
                    <td>
                      <span>{data.cityOrganisation},</span>
                      <span className="text-bold">
                        {data.countryOrganisation}
                      </span>
                      <br />
                      <span>BP XXXX Ouagadougou</span>
                    </td>
                    <td className="text-center">
                      <div className="btn-group">
                        <div className="d-inline-block mx-1">
                          <img
                            title="Voir l’organisation"
                            onClick={(e) => {
                              navigate(`detail/${data.registrationReference}`);
                            }}
                            src={view}
                            alt=""
                          />
                        </div>
                        <div className="d-inline-block mx-1">
                          <img
                            title="Éditer l'l’organisation"
                            onClick={(e) => {
                              navigate(
                                `formulaire/${data.registrationReference}`
                              );
                            }}
                            src={edit}
                            alt=""
                          />
                        </div>
                        <div className="d-inline-block mx-1">
                          <img
                            title="Supprimer l’organisation"
                            data-bs-toggle="modal"
                            data-bs-target="#deleteEmploye"
                            onClick={(e) => {
                              e.preventDefault();
                              setDelete(["" + data.registrationReference]);
                            }}
                            src={del}
                            alt=""
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Loading>

      <div className="d-inline-block my-1 me-1">
        <img
          title="Supprimer les organisations sélectionnés"
          data-bs-toggle="modal"
          data-bs-target="#deleteEmploye"
          src={del}
          alt=""
        />
      </div>
    </>
  );
};

export default ListOrganisation;
