import React, { useContext, useEffect, useRef, useState } from "react";
import view from "../assets/imgs/view.png";
import edit from "../assets/imgs/edit.png";
import disable from "../assets/imgs/disable.png";
import back from "../assets/imgs/back.png";
import sui from "../assets/imgs/sui.png";
import requestEmploye from "../services/requestEmploye";
import { apiEmploye, apiOrganisation } from "../services/api";
import { useNavigate } from "react-router-dom";
import requestOrganisation from "../services/requestOrganisation";
import { AppContext } from "../services/context";
import Loading from "../components/Loading";
import { matrice, onSearch } from "../services/service";

const WidocUser = () => {
  const [refresh, setRefresh] = useState(0);
  const [search, setSearch] = useState("");
  const [dele, setDelete] = useState([]);
  const [datas, setDatas] = useState([]);
  const [list, setList] = useState([]);

  let navigate = useNavigate();
  const authCtx = useContext(AppContext);
  const { user } = authCtx;
  const [stopLoad, setStopLoad] = useState(false);
  const [fail, setFail] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const [modalNotifyMsg, setModalNotifyMsg] = useState("");
  const notifyRef = useRef();
  useEffect(() => {
    isAuth();
    get();
  }, [refresh]);

  const isAuth = () => {
    console.log(user);
    if (user.isAuth === false || user.token == null || user.token === "") {
      console.log(`connexion échoué, isAuth`);
      console.log(user);

      return navigate("/");
    }
  };

  const get = () => {
    requestOrganisation
      .get(apiOrganisation.widoc)
      .then((res) => {
        const data = matrice(res.data);
        setPagination(data.list);
        if (data.list.length !== 0) {
          setDatas(data.list[0]);
          setList(data.list[0]);
          setTotalPage(data.counter);
        } else {
          setTotalPage(data.counter + 1);
        }
        setStopLoad(true);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
        setFail(true);
      });
  };

  const blockUser = (userData) => {
    
    requestOrganisation
      .patch(apiOrganisation.widoc + "/" + userData.uuid)
      .then((res) => {
        console.log(res.data);
        const msg = !userData.accountLocked
      ? "Le compte a été bloqué"
      : "Le compte a été débloqué";
    setModalNotifyMsg(msg);

        notifyRef.current.click();
        get()
      })
      .catch((error) => {
        console.log(error);
        const msg = !userData.accountLocked
      ? "Oups ! Une erreur est survenue lors du blocage du compte"
      : "Oups ! Une erreur est survenue lors du déblocage du compte";
    setModalNotifyMsg(msg);

        notifyRef.current.click();
      });
  };

  const deleteList = (data) => {
    if (data.checkValue === undefined || data.checkValue === false) {
      data.checkValue = true;
      setDelete([...dele, data.registrationReference]);
    } else {
      data.checkValue = false;
      const list = dele.filter(function (ref) {
        return ref !== data.registrationReference;
      });
      setDelete(list);
    }
  };

  const makeSearch = (e) => {
    e.preventDefault();
    onSearch(e, setList, datas, [
      "firstname",
      "lastname",
      "country",
      "city",
      "phone",
      "email",
    ]);
  };
  const makePagination = (e, page) => {
    e.preventDefault();
    if (page === "suiv" && pageNumber < Number(totalPage) - 1) {
      setPageNumber(pageNumber + 1);
      setList(pagination[pageNumber + 1]);
      setDatas(pagination[pageNumber + 1]);
    }
    if (page === "prece" && pageNumber >= 1) {
      setPageNumber(pageNumber - 1);
      setList(pagination[pageNumber - 1]);
      setDatas(pagination[pageNumber - 1]);
    }
  };
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 className="h2">Liste des utilisateurs Widoc</h1>
      </div>

      <div className="row my-4">
        <div className="col-12">
          <div className="d-inline-block">
            <input
              type="text"
              className="form-control search"
              placeholder="Nom, contact ou adresse de l'utilisateur"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                makeSearch(e);
              }}
            />
          </div>
          <div className="btn-group">
            <div
              className="d-inline-block my-1 mx-1"
              onClick={(e) => makePagination(e, "prece")}
            >
              <img src={back} alt="" />
            </div>
            <div
              className="d-inline-block my-1 mx-1"
              onClick={(e) => makePagination(e, "suiv")}
            >
              <img src={sui} alt="" />
            </div>
          </div>
          <div className="d-inline-block my-1 mx-1 text-meduim text-bold">
            {pageNumber + 1}/{totalPage}
          </div>
        </div>
      </div>
      <p className="text-ultra-small">{list.length} éléments affichés</p>
      <Loading data={list} stopLoad={stopLoad} fail={fail}>
        <div className="table-responsive-sm">
          <table className="table table-striped align-middle">
            <thead>
              <tr className="align-middle">
                <th scope="col" className="border-raduis-left">
                  #
                </th>
                <th scope="col">Nom et Prénom(s)</th>
                <th scope="col">Contacts</th>
                <th scope="col">Compte</th>
                <th scope="col">Adresse</th>
                <th scope="col" className="text-center">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {list.map((data, idx) => {
                //data.checkValue = false
                return (
                  <tr key={idx}>
                    <td>
                      <input
                        type="checkbox"
                        value="seleted"
                        onChange={() => {
                          deleteList(data);
                        }}
                      />
                    </td>
                    <td>{data.lastname + " " + data.firstname}</td>
                    <td>
                      <span className="text-bold">{data.email}</span>
                      <br />
                      <span>{data.phone}</span>
                    </td>
                    <td>{data.accountLocked ? "Bloqué" : "Actif"}</td>
                    <td>
                      <span>{data.city},</span>
                      <span className="text-bold">{data.country}</span>
                      <br />
                      {/**<span>BP XXXX Ouagadougou</span> */}
                    </td>
                    <td className="text-center">
                      <div className="btn-group">
                        <div className="d-inline-block mx-1">
                          <img
                            title="Supprimer l’organisation"
                            onClick={(e) => {
                              e.preventDefault();
                              blockUser(data);
                            }}
                            src={disable}
                            alt=""
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Loading>
      <div className="modal fade" id="notifyRef">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h4 className="modal-title text-meduim text-bold"></h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">{modalNotifyMsg}</div>

            <div className="modal-footer border-0 d-flex justify-content-start">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  setModalNotifyMsg("");
                  //navigate("/dashboard/organisations")
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
      <input
        type="hidden"
        ref={notifyRef}
        data-bs-toggle="modal"
        data-bs-target="#notifyRef"
        onClick={(e) => {
          e.preventDefault();
        }}
      />
    </>
  );
};

export default WidocUser;
