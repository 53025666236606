import React, { useContext, useEffect } from "react";
import profile from "../assets/imgs/profile.png";
import del from "../assets/imgs/delete.png";
import { AppContext } from "../services/context";
import { getUser } from "../services/storage";
import { useNavigate } from "react-router-dom";


const Settings = () => {
  const authCtx = useContext(AppContext);
  const { user, onUserChange } = authCtx;
  let navigate = useNavigate();
  useEffect(()=>{
    onUserChange(getUser())
    isAuth()
  },[user.isAuth])
  const isAuth = () => {
    if (user.isAuth === false || user.token === null || user.token === "") {
      console.log(`connexion échoué, isAuth`);
      console.log(user)
  
      return navigate("/");
    }
  }
  return (
    <>
      <div className="row">
        <h1 className="h2">Paramètres de mon compte</h1>
      </div>
      <div className="row my-4">
        <div className="col-10 col-sm-8 mx-auto col-md-5 col-lg-4">
          <img width="100%" src={profile} alt="" />
          <div className="my-3">
            <button className="btn btn-primary me-2" data-bs-dismiss="modal" style={{width:"85%"}}>
              Modifier la photo
            </button>
            <img src={del} alt="" />
          </div>
          <div className="border border-1 border-radius p-4 my-4">
            <p className="text-16 text-bold ps-1">Autres actions</p>
            <button
              className="btn border border-1 border-radius my-2 w-100 text-bold text-start"
              data-bs-toggle="modal"
              data-bs-target="#editPassword"
            >
              Modifier le mot de passe
            </button>
            <button
              className="btn border border-1 border-radius my-2 w-100 text-bold text-start"
              data-bs-dismiss="modal"
            >
              Aide et support
            </button>
            <button
              className="btn border border-1 border-radius my-2 w-100 text-bold text-start"
              data-bs-dismiss="modal"
            >
              Conditions d’utilisation
            </button>
          </div>
        </div>
        <div className="col-12 col-md-7 col-lg-6 mx-auto border border-1 border-radius p-4">
          <p className="text-16 text-bold">Mes informations personnelles</p>
          <form className="w-100" action="">
            <div className="mb-3 mt-3">
              <label htmlFor="lname" className="form-label">
                Nom
              </label>
              <input
                type="text"
                className="form-control"
                id="lname"
                placeholder="Entrer le nom de famille de l’employé(e)"
                name="lname"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="fname" className="form-label">
                Prénom(s)
              </label>
              <input
                type="name"
                className="form-control"
                id="fname"
                placeholder="Entrer le ou les prenom(s) de l’employé(e)"
                name="fname"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="fname" className="form-label">
                Date de naissance
              </label>
              <input
                type="name"
                className="form-control"
                id="fname"
                placeholder="Entrer la date de naissance"
                name="fname"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="fname" className="form-label">
                Numéro CNI
              </label>
              <input
                type="name"
                className="form-control"
                id="fname"
                placeholder="Entrer le numéro CNI"
                name="fname"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="fname" className="form-label">
                Email
              </label>
              <input
                type="name"
                className="form-control"
                id="fname"
                placeholder="Entrer l’adresse mail"
                name="fname"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="fname" className="form-label">
                Telephone
              </label>
              <input
                type="name"
                className="form-control"
                id="fname"
                placeholder="Entrer le numero de téléphone"
                name="fname"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="fname" className="form-label">
                Classification
              </label>
              <select className="form-select">
                <option>Choisir la classification</option>
                <option>classification 1</option>
                <option>classification 2</option>
                <option>classification 3</option>
                <option>classification 4</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="fname" className="form-label">
                Spécialisation
              </label>
              <input
                type="name"
                className="form-control"
                id="fname"
                placeholder="Entrer la spécialisation"
                name="fname"
              />
            </div>
            <div className="d-flex justify-content-start border-0">
              <button
                type="reset"
                className="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Ajouter l’employé(e)
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="modal fade" id="editPassword">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h4 className="modal-title text-meduim text-bold">
                Modification du mot de passe
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <form >
                <div className="mb-3 mt-3">
                  <label htmlFor="lname" className="form-label">
                  Nouveau mot de passe
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="lname"
                    placeholder="Entrer le nouveau mot de passe"
                    value={""}
                    onChange={(e) => {
                      e.preventDefault();
                      
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="fname" className="form-label">
                    Confirmation du nouveau mot de passe
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="fname"
                    placeholder="Confirmer le nouveau mot de passe"
                    value={""}
                    onChange={(e) => {
                      e.preventDefault();
                      
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="date" className="form-label">
                    Ancien mot de passe
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="date"
                    placeholder="Entrer l’ancien mot de passe"
                    value={""}
                    onChange={(e) => {
                      e.preventDefault();
                      
                    }}
                  />
                </div>
                <div className="modal-footer d-flex justify-content-start border-0">
                  <button
                    type="reset"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Fermer
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Enrégistrer les modifications
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
