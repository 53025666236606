
const apiEmploye = {
    "getJsData":"employee-request-form",
    "getAll":"get/employee",
    "get":"employee-request",
    "post":"employee-request",
    "put":"employee-update-form",
    "delete":"employee-delete-list",
}

const apiOrganisation = {
    "getJsData":"organisation-request-form",
    "getAll":"organisation-getlist-request",
    "get":"organisation-request",
    "post":"organisation-registration",
    "put":"organisation-update",
    "delete":"organisation-delete",
    "image":"document-head",
    "widoc":"user-patient"
}

const apiUser = {
    "login":"auth/login-super-admin"
}

const apiDrug = {
    drugs:"settings/drugs",
    getCategorie:"settings/drug-categories",
    settings:"settings"
}




export {
    apiEmploye,
    apiOrganisation,
    apiUser,
    apiDrug
}