import React, { useContext, useEffect, useState } from "react";
import view from "../assets/imgs/view.png";
import edit from "../assets/imgs/edit.png";
import del from "../assets/imgs/delete.png";
import back from "../assets/imgs/back.png";
import sui from "../assets/imgs/sui.png";
import userProfile from "../assets/imgs/userinfo.png";
import requestEmploye from "../services/requestEmploye";
import { apiEmploye } from "../services/api";
import FormNotify from "../components/FormNotify";
import { Route, Routes, useNavigate } from "react-router-dom";
import ListOrganisation from "./organisation/ListOrganisation";
import FormOrganisation from "./organisation/FormOrganisation";
import DetailOrganisation from "./organisation/DetailOrganisation";
import { AppContext } from "../services/context";
import { getUser } from "../services/storage";

const Organisation = () => {
  const authCtx = useContext(AppContext);
  const { user, onUserChange } = authCtx;
  let navigate = useNavigate();
  useEffect(()=>{
    onUserChange(getUser())
    isAuth()
  },[user.isAuth])
  const isAuth = () => {
    if (user.isAuth === false || user.token === null || user.token === "") {
      console.log(`connexion échoué, isAuth`);
      console.log(user)
  
      return navigate("/");
    }
  }
  return (
    <>
      <Routes>
        <Route path="/" element={<ListOrganisation />} />
        <Route path="formulaire" element={<FormOrganisation/>} />
        <Route path="formulaire/:id" element={<FormOrganisation/>} />
        <Route path="detail/:id" element={<DetailOrganisation/>} />
      </Routes>
    </>
  );
};

export default Organisation;
